import React, { useState, useEffect, useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { marked } from 'marked';

import {
  BREAKPOINT,
  COLOR,
  MARGIN,
  ALIGN,
  PL_PRODUCT_ID,
  SOFT_QUOTE_LOANS_AU_URL,
  FONT_WEIGHT
} from '@/utils/constants';

import { Box } from '@latitude/box';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { graphql } from 'gatsby';
import { FeaturesSlider } from '@latitude/features-slider';
import { Link } from '@latitude/link';
import { Accordion } from '@latitude/accordion';
import { Heading4 } from '@latitude/heading';
import { ImportantInformation } from '@latitude/important-information';
import { List, ListItem } from '@latitude/list';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';

import Modal from '@/components/Modal/Modal';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import HeroBranded, { HeroImage } from '@/components/HeroBranded/HeroBranded';
import Layout from '@/components/layout';
import { CardGroup, CardGroupItem } from '@/components/CardGroup/CardGroup';
import LoanCardBranded from '@/components/LoanCard/LoanCardBranded';
import PageHeaderSection from '@/components/PageLayout/PageHeader';
import Section from '@/components/Section/Section';

import { Heading3 } from '@/components/Heading/Heading';
import CalculatorLinks from '@/components/CalculatorLinks/CalculatorLinks';
import FeaturesStaggeredSlider from '@/components/FeaturesStaggeredSlider/FeaturesStaggeredSlider';
import ManageYourLoan from '@/components/ManageYourLoan/ManageYourLoan';
import Text from '@/components/Text/Text';
import { Strong } from '@/components/Text/Bold';
import footerData from '@/data/json/footer.json';
import FeesAndCharges from '@/components/PersonalLoanDetails/PersonalLoanDetails';

import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';

import heroImage from '../images/hero/loans.webp';

import Productdata from '../data/json/Products/products.json';
import ArticleTile from '../templates/life-done-better/ArticleTile';
import PageData from '../data/pages/loans.json';
import PLData from '../data/pages/personal-loan.json';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import { NotificationBanner } from '@latitude/banner';

const LoansPage = props => {
  const applyUrl = `${getUrlFromEnv(
    'funnel-start-loans'
  )}${SOFT_QUOTE_LOANS_AU_URL}`;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fixedVsVariableModalIsOpen, setFixedVsVariableModalIsOpen] = useState(
    false
  );
  const PL_TRACK_PRODUCT_LOCATION = 'Personal Loan';

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'Why choose a Latitude loan?',
      featureCards: PLData.content.whyChooseLatitude
    }
  ];
  /** - END - */

  return (
    <Layout location={props.location} customFooter={footerData}>
      <MobileAppInstallPrompts />
      <main
        className="navigation-spacer"
        css={`
          background-color: #f8f8f8;
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/loans/"
          />
          <meta
            name="description"
            content="We offer loan types to suit many situations, including buying a car, renovating a home or travelling. Explore our personal loans & vehicle finance options."
          />
          <title>Latitude Loans | Latitude Financial</title>
        </Helmet>
        {!state?.alertData && (
          <NotificationBanner siteName="latitudefinancial-com-au" />
        )}
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            title="Latitude Loans"
            subTitle={
              <>
                Need money for a shiny new car, an overseas adventure, or
                simplifying your repayments? Whatever your goals, we’ll try and
                get you there. Explore your options below.
              </>
            }
            pageImage={heroImage}
            pageImageAlt=""
          />
        )}
        <div className="d-none d-lg-block" css="position:relative; z-index:10;">
          <StickyNavigationBranded
            items={PageData.nav}
            offsetElem="[data-sticky-navigation-offset]"
          />
        </div>

        <Section
          id="details"
          css={`
            && {
              background-color: #f8f8f8;
              padding-top: 40px;
              padding-bottom: 40px;
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-top: 72px;
                padding-bottom: 144px;
                .loan-card__header {
                  min-height: 274px;
                }
              }
            }
          `}
        >
          <Heading3
            css={`
              font-size: 24px;
              color: ${COLOR.BLACK};
              text-align: center;
              @media (min-width: ${BREAKPOINT.LG}) {
                font-size: 32px;
              }
            `}
          >
            Our Latitude loans
          </Heading3>
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-sm-8 col-md-7 col-lg-12">
              <CardGroup>
                <CardGroupItem col={2}>
                  <LoanCardBranded
                    icon="icon-pl"
                    isBranded
                    className="h-100"
                    css={`
                      .loan-card__button-container a.loan-card__button {
                        width: auto;
                        padding-left: 10px;
                        padding-right: 10px;
                      }
                    `}
                    title={
                      <span>
                        Personal
                        <br /> Loan
                      </span>
                    }
                    description="Renovating? Travelling? Or getting married? Whatever you need, let’s do it with a Latitude Personal Loan."
                    rateBoxType="variableSym"
                    rateBoxType2="fixedSym"
                    rateBoxFullWidth
                    listItems2Title={<Strong>Loan Details:</Strong>}
                    listItems2={[
                      'Loan amounts from $5,000',
                      'Loan terms from 2 - 7 years',
                      'Secured and unsecured loans',
                      'Fixed and Variable loans',
                      'Weekly, fortnightly or monthly repayments'
                    ]}
                    button1={{
                      href: '/personal-loan/',
                      trackId: 'personal-loan-tell-me-more',
                      text: 'Tell me more'
                    }}
                    button2={{
                      href: applyUrl,
                      trackId: 'personal-loan-get-my-rate',
                      text: 'Get Rate Estimate',
                      target: '_self',
                      trackEventData: {
                        category: 'cta',
                        action: 'quote-link'
                      }
                    }}
                    trackProductLocation={PL_TRACK_PRODUCT_LOCATION}
                    trackProductId={[PL_PRODUCT_ID]}
                  />
                  <ModalLinks
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    fixedVsVariableModalIsOpen={fixedVsVariableModalIsOpen}
                    setFixedVsVariableModalIsOpen={
                      setFixedVsVariableModalIsOpen
                    }
                  />
                </CardGroupItem>
              </CardGroup>
            </div>
          </div>
        </Section>
        <div id="fees">
          <FeesAndCharges data={PLData.content.feesAndCharges} />
        </div>
        <FeaturesSlider
          key={(state?.featureSliderData?.[0] || featureSliderData[0]).heading}
          id="why-us"
          data={
            (state?.featureSliderData?.[0] || featureSliderData[0]).featureCards
          }
          className="why-choose pt-4 pt-md-5"
          heading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).heading
          }
          subheading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).description
          }
        />
        <div id="calculate">
          <CalculatorLinks />
        </div>

        <div
          id="faq"
          css={`
            && {
              background-color: #f8f8f8;
              padding-top: 10px;
              padding-bottom: 0;
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-top: 42px;
                padding-bottom: 0;
              }
            }
          `}
        >
          <AnalyticsLocationProvider location="Let’s look at the nuts and bolts">
            <ManageYourLoan
              heading="Let’s look at the nuts and bolts"
              description="Let’s do this together, answering all your questions, helping you manage your loan."
            >
              <Accordion
                titleBgColor="grey-light"
                items={[
                  {
                    id: 'item-1',
                    title: 'How much can I borrow?',
                    // initiallyActive: true,
                    content: (
                      <Text>
                        The minimum amount you can borrow for a Latitude
                        Personal Loan is $5,000, with the maximum depending on
                        your circumstances and current financial situation.
                      </Text>
                    )
                  },
                  {
                    id: 'item-2',
                    title: 'When will I get a decision on my application?',
                    content: (
                      <Text>
                        In most cases, you can expect to receive a decision on
                        your loan application in 1-2 working days.
                      </Text>
                    )
                  },
                  {
                    id: 'item-3',
                    title: 'How long until I receive my funds?',
                    content: (
                      <Text>
                        Once your loan is approved and signed, your funds are
                        usually available the next day or no more than two
                        working days (depending on your circumstances).
                      </Text>
                    )
                  }
                ]}
              />
            </ManageYourLoan>
          </AnalyticsLocationProvider>
        </div>

        <FeaturesStaggeredSlider
          css={`
            background-color: #f8f8f8;
            .card-icon__image::after {
              background: transparent !important;
            }
            .slick-track .slick-slide:nth-child(2n + 2) {
              -webkit-transform: none !important;
              -ms-transform: none !important;
              transform: none !important;
            }
            .lfs-slide {
              box-shadow: 0 3px 2px 0px rgba(0, 0, 0, 0.25) !important;
            }
            .card-icon h5 {
              font-size: 18px !important;
              margin: 10px 0 !important;
            }
            .card-icon__content p {
              color: #696969 !important;
            }
            .whats-important .slick-slider .slick-slide.slick-active {
              margin: 0 5px !important;
            }
          `}
          data={PageData.categories}
          withBtn
          className="whats-important pb-md-5"
          heading="What can I use this loan for?"
          responsiveSettings={[
            {
              breakpoint: 10000,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 0,
                infinite: false
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true
              }
            },
            {
              breakpoint: 460,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true
              }
            },
            {
              breakpoint: 450,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true
              }
            }
          ]}
        />

        <Box.Section
          backgroundColor={COLOR.GREY5}
          paddingTop={MARGIN.M32}
          paddingBottom={MARGIN.M32}
        >
          <AnalyticsLocationProvider location="Recommended reads">
            <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
              Recommended reads
            </Heading4>
            <CardGroup>
              {props.data.allMarkdownRemark.edges.map(item => (
                <CardGroupItem key={item.node.frontmatter.path}>
                  <ArticleTile isBranded {...item.node.frontmatter} />
                </CardGroupItem>
              ))}
            </CardGroup>
          </AnalyticsLocationProvider>
        </Box.Section>

        <ImportantInformation
          data={require('../data/json/disclaimer/personal-loan.json')}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          additionalPaymentsDisclaimerOne
        />
      </main>
    </Layout>
  );
};

const StyledHeroBranded = styled(HeroBranded)`
  ${HeroImage} {
    height: 260px;

    @media (min-width: ${BREAKPOINT.MD}) {
      height: auto;
    }
  }
`;

const ModalLinks = props => {
  const Outer = styled.div`
    @media (min-width: ${BREAKPOINT.LG}) {
      position: absolute;
    }
    svg {
      width: 19px;
      height: 19px;
      top: 4px;
    }
    .loan-type-link {
      color: ${COLOR.BLACK};
      font-weight: ${FONT_WEIGHT.LIGHT};
    }
    .loan-type-link:hover {
      text-shadow: none;
    }
  `;

  return (
    <Outer>
      <div className="loan-type">
        <svg height="16px" width="16px" className="exclamation" role="img">
          <use
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="/assets/icons/icons-sprite.svg#info-block"
          />
        </svg>
        <Link
          onClick={() => {
            props.setModalIsOpen(true);
          }}
          // eslint-disable-next-line no-script-url
          href="javascript:;"
          role="presentation"
          className="loan-type-link"
          data-trackid="rates-secured-vs-unsecured-link"
        >
          Secured vs Unsecured loan?
        </Link>
      </div>

      <div className="loan-type">
        <svg height="16px" width="16px" className="exclamation" role="img">
          <use
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="/assets/icons/icons-sprite.svg#info-block"
          />
        </svg>
        <Link
          onClick={() => {
            props.setFixedVsVariableModalIsOpen(true);
          }}
          href="javascript:;"
          role="presentation"
          className="loan-type-link"
          data-trackid="rates-fixed-vs-variable-link"
        >
          Fixed vs Variable loan?
        </Link>
      </div>

      <div className="loan-type">
        <svg height="16px" width="16px" className="exclamation" role="img">
          <use
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="/assets/icons/icons-sprite.svg#info-block"
          />
        </svg>
        <Link
          className="loan-type-link"
          data-trackid="fees-and-charges-determine-rate"
          href="/how-interest-rates-are-determined/"
        >
          How is my interest rate determined?
        </Link>
      </div>

      <Modal
        isOpen={props.modalIsOpen}
        onRequestClose={() => {
          props.setModalIsOpen(false);
        }}
        title={Productdata.securedUnsecuredText.title}
        content={Productdata.securedUnsecuredText.content}
        ariaHideApp={props.modalIsOpen}
      />
      <Modal
        isOpen={props.fixedVsVariableModalIsOpen}
        onRequestClose={() => {
          props.setFixedVsVariableModalIsOpen(false);
        }}
        title="Fixed vs Variable loan?"
        content={
          <>
            When applying for a personal loan, one decision you need is to make
            is whether you want a fixed or variable loan.
            <br />
            <br />
            <strong>Fixed loan:</strong>
            <List>
              <ListItem>
                Your rate and repayments remain fixed for the life of your loan
              </ListItem>
              <ListItem>
                An early termination fee of $500 applies if you payout your loan
                in full up until the last 3 months of the loan. No early
                termination fee applies in the final 3 months of the loan
              </ListItem>
            </List>
            <br />
            <strong>Variable loan:</strong>
            <List>
              <ListItem>
                Your rate and repayments could change, up or down, during your
                loan term
              </ListItem>
              <ListItem>No early termination fee</ListItem>
            </List>
          </>
        }
        ariaHideApp={props.setFixedVsVariableModalIsOpen}
      />
    </Outer>
  );
};

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
          glob: "**/src/templates/life-done-better/articles/*.md"
        }
        frontmatter: {
          title: {
            in: [
              "How to Consolidate Debt: The All-in-One Guide"
              "Is a Debt Consolidation Loan Right for You?"
              "Tips to Help You Become a Super Saver"
            ]
          }
        }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            thumbnail
          }
        }
      }
    }
  }
`;

export default LoansPage;
